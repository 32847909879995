import React, { Component } from "react";
import MenuProjects from "./MenuProjects";

export default class Projects extends Component {
  render() {
    return (
      <>
        <MenuProjects />
      </>
    );
  }
}
